<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card>
          <template v-slot:heading>
            <h3 class="display-2">Struktur Sistem Kerja</h3>
          </template>

          <v-toolbar flat>
            <div style="max-width:400px">
              <v-autocomplete v-model="selectSKPD" :items="skpd" label="SKPD" item-text="v" item-value="k" hide-details clearable></v-autocomplete>
            </div>
            <div style="max-width:400px" class="mx-5">
              <v-text-field v-model="search" hide-details label="Cari" append-icon="mdi-magnify"></v-text-field>
            </div>
            <v-spacer></v-spacer>
            <v-btn class="green" dark @click="createItem()">Tambah Pokja</v-btn>
          </v-toolbar>

          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="totalKeseluruhan"
            :page.sync="page"
            :items-per-page.sync="rowPerPage"
            :loading="loading"
            :footer-props="{'items-per-page-options':[10,20,30]}"
          >
            <template v-slot:item.aksi="{item}">
              <div class="d-flex flex-column">
                <v-btn @click="edit(item)" x-small class="warning ma-1" dark><v-icon small>mdi-pencil</v-icon> Edit</v-btn>
                <v-btn @click="hapus(item)" x-small class="error ma-1" dark><v-icon small>mdi-delete</v-icon> Hapus</v-btn>
                <v-btn @click="lihat(item)" x-small class="primary ma-1" dark><v-icon small>mdi-eye</v-icon> Lihat</v-btn>
              </div>
            </template>
            <template v-slot:item.nama_pokja="{item}">
              <b>{{item.nama_pokja}}</b> 
            </template>
            <template v-slot:item.nip_ketua="{item}">
              <br>
              <b>NIP:</b> {{item.data_ketua.nip}}
              <br>
              <b>Nama: </b>{{item.data_ketua.nama}}
              <br>
              <b>Jabatan:</b> {{item.data_ketua.jabatan}}
              <br>
              <br>
              <v-avatar tile size="100"> <v-img :src="item.data_ketua.foto"></v-img></v-avatar>
              <br>
              <br>
            </template>
            <template v-slot:item.nip_atasan="{item}">
              <br>
              <b>NIP:</b> {{item.data_atasan_ketua.nip}}
              <br>
              <b>Nama: </b>{{item.data_atasan_ketua.nama}}
              <br>
              <b>Jabatan:</b> {{item.data_atasan_ketua.jabatan}}
              <br>
              <br>
              <v-avatar tile size="100"> <v-img :src="item.data_atasan_ketua.foto"></v-img></v-avatar>
              <br>
              <br>
            </template>
            <template v-slot:item.jumlah_anggota="{item}">
              <b>{{item.jumlah_anggota != null ? 'Maksimal: '+item.jumlah_anggota+ ' orang' : '-'}}</b><br><br>
              <b><font :color="checkJumlahAnggota(item)">{{'Terisi: '+item.data_anggota.length+ ' orang'}}</font></b>
            </template>
            <template v-slot:item.tanggal_mulai="{item}">
              {{gFD(item.tanggal_mulai)}}
            </template>
            <template v-slot:item.tanggal_selesai="{item}">
              <b><font :color="check(item)">{{gFD(item.tanggal_selesai)}}</font></b>
            </template>
            <template v-slot:item.opd="{item}">
              <br>
              <b>Kode SKPD:</b> {{item.opd}}
              <br>
              <b>SKPD: </b>{{item.satuan_kerja}}
              <br>
              <b>Unit Kerja:</b> {{item.unit_kerja}}
              <br>
              <br>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>

    <!-- The following below is the form for adding struktur pokja / sistem kerja -->
    <v-dialog v-model="dialogTambah" max-width="600px" persistent>
      <v-card>
        <v-card-text>
          <v-form ref="formEdit">
            <v-row>
              <v-col>
                <h1 class="display-2 my-2">Tambah Kelompok Kerja (Pokja)</h1>
              </v-col>
              <v-col>
                <div class="d-flex flex-row-reverse">
                <v-btn icon color="primary" @click="dialogTambah = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-spacer></v-spacer>
            <v-select class="mt-10" v-model="formEdit.satuan_kerja_id" @change="loadUkerAndPegawai()" :items="skpd" :rules="[rules.required]" label="SKPD" item-text="v" item-value="k" dense outlined></v-select>
            <v-select :loading="unit_kerja_l" v-model="formEdit.unit_kerja_id" :items="unit_kerja" :rules="[rules.required]" label="Unit Kerja SKPD" item-text="v" item-value="k" dense outlined></v-select>

            <v-textarea v-model="formEdit.nama_pokja" :rules="[rules.required]" noResize dense label="Nama Pokja" outlined ></v-textarea>
            <v-text-field v-model="formEdit.jumlah_anggota" :rules="[rules.required, rules.onlyInt]" noResize dense label="Jumlah Anggota Pokja" outlined ></v-text-field>
            <!-- <v-text-field v-model="formEdit.nip_ketua" dense label="Nip Ketua Pokja (Opsional)" outlined ></v-text-field> -->
            <!-- <v-text-field v-model="formEdit.nip_atasan" dense label="Nip Atasan (Opsional)" outlined ></v-text-field> -->
            <v-autocomplete
              :items="listPegawaiSKPD"
              v-model="formEdit.ketua"
              label="Nip Ketua Pokja (Opsional)"
              :item-text="getNipNama"
              return-object
              outlined
              @change="lookUpAtasan()"
            >
            </v-autocomplete>
            <v-autocomplete
              :items="listPegawaiSKPD"
              v-model="formEdit.atasan"
              label="Nip Atasan (Opsional)"
              :item-text="getNipNama"
              return-object
              outlined
            >
            </v-autocomplete>

            <v-menu
              v-model="fromDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              min-width="290px"
              transition="scale-transition"
              lazy offset-y full-width>
              <template v-slot:activator="{ on }">
                <v-text-field label="Tanggal Mulai" :rules="[rules.required]" append-icon="mdi-calendar" outlined dense readonly v-model="fromDateVal" v-on="on"></v-text-field>
              </template>
              <v-date-picker
              locale="in-ID"
              v-model="fromDateVal"
              no-title
              @input="fromDateMenu = false"
              >
              </v-date-picker>
            </v-menu>

            <v-menu
              v-model="endDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              min-width="290px"
              transition="scale-transition"
              lazy offset-y full-width>
              <template v-slot:activator="{ on }">
                <v-text-field label="Tanggal Selesai" :rules="[rules.required]" append-icon="mdi-calendar" outlined dense readonly v-model="endDateVal" v-on="on"></v-text-field>
              </template>
              <v-date-picker
              locale="in-ID"
              v-model="endDateVal"
              no-title
              @input="endDateMenu = false"
              >
              </v-date-picker>
            </v-menu>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="tutup()">Tutup</v-btn>
          <v-btn color="success" @click="simpanItem()">Simpan</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- The following below is the form for deleting struktur pokja / sistem kerja -->
    <v-dialog v-model="dialogHapus" max-width="600px" persistent>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col>
              <h1 class="display-2 my-2">Hapus Kelompok Kerja (Pokja)</h1>
            </v-col>
            <v-col>
              <div class="d-flex flex-row-reverse">
              <v-btn icon color="primary" @click="dialogHapus = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
          <div class="mt-5">
            <label>Pokja: {{this.formEdit.nama_pokja}}</label><br>
            <label>Unit Kerja: {{this.formEdit.unit_kerja}}</label><br>
            <label>Satuan Kerja: {{this.formEdit.satuan_kerja}}</label><br>
            <h3>Apakah Anda Yakin?</h3>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="tutup()">Tutup</v-btn>
          <v-btn color="error" @click="hapusItem()">Hapus</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- The following below is the form for deleting struktur pokja / sistem kerja -->
    <v-dialog v-model="dialogLihat" max-width="1200px" persistent>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col>
              <h1 class="display-2 my-2">Kelompok Kerja {{views.nama_pokja}}</h1>
            </v-col>
            <v-col>
              <div class="d-flex flex-row-reverse">
              <v-btn icon color="primary" @click="dialogLihat = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              </div>
            </v-col>
          </v-row>
          <!-- <h1 class="display-2 my-2">Kelompok Kerja {{views.nama_pokja}}</h1> -->
          <v-spacer></v-spacer>
          <div class="mt-5">
            <label class="display-1">Pokja: {{views.nama_pokja}}</label><br>
            <label class="display-1">Unit Kerja: {{views.unit_kerja}}</label><br>
            <label class="display-1">Satuan Kerja: {{views.satuan_kerja}}</label><br>
            <label class="display-1"><b><font color="error">Maksimum Anggota: {{views.jumlah_anggota == null ? 'Silahkan Isi Dahulu Jumlah Anggota' : views.jumlah_anggota+' Orang'}}</font></b></label>
          </div>
          <v-row>
            <v-col></v-col>
            <v-col>
                <div>
                  <v-text-field v-model="searchAnggota" hide-details label="Cari" append-icon="mdi-magnify"></v-text-field>
                </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div v-if="loading">
                <v-row class="mt-5">
                  <v-col>
                    <v-skeleton-loader
                    class="mx-auto"
                    min-width="200"
                    type="table"
                    height="auto"
                    ></v-skeleton-loader>
                  </v-col>
                </v-row>
              </div>
              <v-simple-table>
                <thead class="green lighten-3">
                  <tr>
                    <th>No</th>
                    <th class="text-center" colspan="2" width="50%">Anggota</th>
                    <th class="text-center">Jabatan</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(peg,i) in views.data_anggota" :key="i" :class="peg.is_ketua == true ? 'green lighten-3' : 'blue lighten-4'">
                    <td>{{ incrementIndex(i) }}</td>
                    <td colspan="2">
                      <div class="ma-3 justify-center align-center text-center">
                        <v-avatar class="rounded-circle" tile size="120"> <v-img :src="peg.foto"></v-img></v-avatar>
                        <br>
                        <b><p style="font-size:14px;margin-bottom:0px !important">{{peg.nip}}</p></b>
                        <b><p style="font-size:14px">{{peg.nama}}</p></b>
                      </div>
                    </td>
                    <!-- <td><b><p style="font-size:14px">{{peg.nama}}</p></b></td> -->
                    <td>
                      <b><p style="font-size:14px">{{peg.jabatan}}</p></b>
                      <br>
                      <v-row>
                        <v-col>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" v-if="peg.is_ketua != true" :loading="loadingBtn.del && loadingEl == i" @click="hapusAnggota(peg, i)" small class="error ma-1" dark><v-icon small>mdi-delete</v-icon></v-btn>
                            </template>
                            <span>Hapus Anggota</span>
                          </v-tooltip>
                        </v-col>
                        <v-col>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" v-if="peg.is_ketua != true" :loading="loadingBtn.upd && loadingEl == i" @click="setAsLeader(peg, i)" small class="ma-1" color="yellow darken-2" dark><v-icon small>mdi-medal</v-icon></v-btn>
                            </template>
                            <span>Atur Sebagai Ketua</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
            <v-col>
              <div v-if="loadingListAnggota">
                <v-row class="mt-5">
                  <v-col>
                    <v-skeleton-loader
                    class="mx-auto"
                    min-width="200"
                    type="table"
                    height="auto"
                    ></v-skeleton-loader>
                  </v-col>
                </v-row>
              </div>
              <v-data-table
                :headers="headersListAnggota"
                :items="listPegawaiSKPDJabatan"
                :search="searchAnggota"
                class="orange lighten-4">
                <template v-slot:item.nomer="{ item, index }">
                {{ index+1 }}
                </template>
                <template v-slot:item.peg_nip="{item}">
                  <!-- <b><p style="font-size:14px">{{item.peg_nip}}-{{item.peg_nama}}</p></b> -->
                  <b><p style="font-size:14px">{{item.peg_nip}}</p></b>
                </template>
                <template v-slot:item.peg_nama="{item}">
                  <b><p style="font-size:14px">{{item.peg_nama}}</p></b>
                </template>
                <template v-slot:item.jabatan="{item}">
                  <b><p style="font-size:14px">{{item.jabatan}}</p></b>
                </template>
                <template v-slot:item.aksi="{item}">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" @click="tambahkanAnggota(item)" small class="primary ma-1" dark><v-icon small>mdi-account-plus</v-icon></v-btn>
                    </template>
                    <span>Tambahkan Anggota</span>
                  </v-tooltip>
                  <!-- <v-btn color="primary" @click="tambahkanAnggota(item)" small>Tambahkan Anggota</v-btn> -->
                </template>
              </v-data-table>
              <!-- <v-simple-table :search="searchAnggota" :custom-filter="filter">
                <thead>
                  <tr>
                    <th class="text-center">Nama</th>
                    <th class="text-center">Jabatan</th>
                    <th class="text-center">#</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(peg,i) in listPegawaiSKPDJabatan" :key="i" class="orange lighten-4">
                    <td><b><p style="font-size:14px">{{peg.peg_nip}}-{{peg.peg_nama}}</p></b></td>
                    <td><b><p style="font-size:14px">{{peg.jabatan}}</p></b></td>
                    <td><v-btn color="primary" @click="tambahkanAnggota(peg)" small>Tambahkan Anggota</v-btn></td>
                  </tr>
                </tbody>
              </v-simple-table> -->
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn @click="tutup()">Tutup</v-btn> -->
          <v-btn @click="dialogLihat = false">Tutup</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from 'lodash'
import OtherService from '@/services/OtherService'
import axios from 'axios'
import _g from '../../global'
import store from '@/store'
import {defaultRules} from '@/utils/lib'
import {local} from '@/store/local'
import SistemKerjaService from '@/services/SistemKerjaService'

export default {
  data(){
    return{
      tableValue:{},
      totalKeseluruhan:0,
      rowPerPage:0,
      search:'',
      selectSKPD:'',
      skpd:[],
      unit_kerja:[],
      page:1,

      headers:[
        {text:'No', value:'nomer', sortable:false, class:'header-index-profile'},
        {text:'Nama Pokja', value:'nama_pokja', sortable:false, class:'header-index-profile'},
        {text:'Ketua', value:'nip_ketua', sortable:false, class:'header-index-profile'},
        {text:'Atasan', value:'nip_atasan', sortable:false, class:'header-index-profile'},
        {text:'Jumlah Anggota', value:'jumlah_anggota', sortable:false, class:'header-index-profile'},
        {text:'Tanggal Mulai', value:'tanggal_mulai', sortable:false, class:'header-index-profile'},
        {text:'Tanggal Selesai', value:'tanggal_selesai', sortable:false, class:'header-index-profile'},
        {text:'SKPD', value:'opd', sortable:false, class:'header-index-profile'},
        {value:'aksi', sortable:false, class:'header-index-profile'},
      ],
      items:[],
      views:[],

      dialogReset:false,
      crudSettings:{
        title:'',
        detailTitle:'Detail',
        enableHeader:true,
        enableDetail:false,
        enableCreate:false,
        dialogTambah:false
      },
      dialogTambah:false,
      dialogHapus:false,
      dialogLihat:false,
      formEdit:{file:[],ketua:{peg_nip:null},atasan:{peg_nip:null}},
      showItem:false,
      allowed: null,
      fromDateMenu: false,
      fromDateVal: null,
      endDateMenu: false,
      endDateVal: null,
      date: new Date(),
      rules:{
        ...defaultRules
      },
      editState:false,
      unit_kerja_l:false,
      startDate_:null,
      endDate_:null,
      dateStart:null,
      dateEnd:null,
      listPegawaiSKPD: [],
      listPegawaiSKPDJabatan: [],
      modelListPegawaiSKPD: [],
      disabled: false,
      avatarImages:{},
      loadingListAnggota:false,
      loading:false,
      pokja_id:null,
      satuan_kerja_id:null,
      loadingEl:-1,
      loadingBtn:{
        add:false,
        del:false,
        upd:false,
        dataPegawai:false
      },
      disabled_atasan: false,
      searchAnggota: '',
      headersListAnggota:[
        {text:'No', value:'nomer', sortable:false, class:'header-index-profile'},
        {text:'Nip', value:'peg_nip', sortable:false, class:'header-index-profile'},
        {text:'Nama', value:'peg_nama', sortable:false, class:'header-index-profile'},
        {text:'Jabatan', value:'jabatan', sortable:false, class:'header-index-profile'},
        {value:'aksi', sortable:false, class:'header-index-profile'},
      ],
      username: ''
    }
  },

  watch:{
    page(val){
      // untuk memastikan tidak reload halaman awal
      if (val > 1){
        this.loading = true
        this.tableValue.page = val
        this.updateTable(this.tableValue)
      }
    }
  },

  created(){
    let current = store.state.user.current
    let user = store.state.user
    this.username = ''
    if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
      current.username = _g.overrideWithPLT(user.current.role)
    }else{
      current.username = user.current.username
    }
    this.username = current.username
    let val = {itemsPerPage:10, page: 1}
    this.updateTable(val)

    OtherService.getSKPD().then(response => {
      let data = []
      _.each(response.data.data, function(value, key, obj){
        data.push({k:key, v: value})
      })
      this.skpd = data
    })
  },

  mounted(){
    this.$watch(vm => [vm.rowPerPage, vm.search, vm.selectSKPD], val => {
      this.page = 1
      this.updateTable({itemsPerPage:this.rowPerPage, skpd:this.selectSKPD, search:this.search, page:this.page})
    })
  },

  computed:{
    filterSemua:{
      get:function(){
        return !this.filterPegawai
      },
      set:function(val){
        this.filterPegawai = !val
      }
    },
    getStartDate() {
      let startDate
      if(this.formEdit.tanggal_mulai != undefined){
        let tanggal_ = new Date(this.formEdit.tanggal_mulai+'T00:00:00')
        startDate = new Date(tanggal_.getFullYear(), tanggal_.getMonth()+1, 1)
        var d = new Date()
        if(tanggal_.getFullYear() < d.getFullYear()){
          startDate = new Date(d.getFullYear(), 1, 1)
        }
      }else{
        startDate = new Date(this.date.getFullYear(), this.date.getMonth(), 1)
      }
      return startDate.getFullYear()+'-'+this.addZero(startDate.getMonth())+'-'+this.addZero(startDate.getDate())
    },
    getEndDate() {
      let endDate
      if(this.formEdit.tanggal_selesai != undefined){
        let tanggal_ = new Date(this.formEdit.tanggal_selesai+'T00:00:00')
        let last_date = new Date(tanggal_.getFullYear(), tanggal_.getMonth() + 1, 0)
        endDate = new Date(tanggal_.getFullYear(), tanggal_.getMonth(), last_date.getDate())
        var d = new Date()
        let last_date_ = new Date(d.getFullYear(), 12, 0)
        if(tanggal_.getFullYear() < d.getFullYear()){
          endDate = new Date(d.getFullYear(), 12, last_date_.getDate())
        }
      }else{
        endDate = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0)
      }
      // return endDate.getFullYear()+'-'+this.addZero(endDate.getMonth()+1)+'-'+this.addZero(endDate.getDate())
      return endDate.getFullYear()+'-'+this.addZero(12)+'-'+this.addZero(endDate.getDate())
    },
    getStartDate_() {
      let startDate_
      if(this.formEdit.tanggal_mulai != undefined){
        let tanggal_ = new Date(this.formEdit.tanggal_mulai+'T00:00:00')
        startDate_ = new Date(tanggal_.getFullYear(), tanggal_.getMonth()+1, 1)
        var dt = new Date()
        if(tanggal_.getFullYear() < dt.getFullYear()){
          startDate_ = new Date(dt.getFullYear(), 1, 1)
        }
      }else{
        startDate_ = new Date(this.date.getFullYear(), this.date.getMonth()+1, 1)
      }
      var d = null
      if(this.fromDateVal != null){
        let date = this.fromDateVal.split("-")
        startDate_ = new Date(date[0], date[1], date[2])
        if(date[1] == '12'){
          d = this.fromDateVal
        }else{
          d = startDate_.getFullYear()+'-'+this.addZero(startDate_.getMonth())+'-'+this.addZero(startDate_.getDate())
        }
      }
      return d
    },

    getEndDate_() {
      let endDate
      if(this.formEdit.tanggal_selesai != undefined){
        let tanggal_ = new Date(this.formEdit.tanggal_selesai+'T00:00:00')
        let last_date = new Date(tanggal_.getFullYear(), tanggal_.getMonth() + 1, 0)
        endDate = new Date(tanggal_.getFullYear(), tanggal_.getMonth(), last_date.getDate())
        var d = new Date()
        let last_date_ = new Date(d.getFullYear(), 12, 0)
        if(tanggal_.getFullYear() < d.getFullYear()){
          endDate = new Date(d.getFullYear(), 12, last_date_.getDate())
        }
      }else{
        endDate = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0)
      }
      return endDate.getFullYear()+'-'+this.addZero(12)+'-'+this.addZero(endDate.getDate())
      // return endDate.getFullYear()+'-'+this.addZero(endDate.getMonth()+1)+'-'+this.addZero(endDate.getDate())
    },
    uploadUrl(){
      return process.env.VUE_APP_API_URL + '/api/mb-upload/pbj/'
    },
    parseFile(){
      if(!this.itemsDataPBJ.foto){
        return '-'
      }else{
        return JSON.parse(this.itemsDataPBJ.foto)
      }
    }
  },

  methods:{
    orange() { return 'orange lighten-4'},
    incrementIndex(key) {
        return key + 1;
    },
    updateTable(val){
      this.items = []
      this.tableValue = val? val:this.tableValue
      console.log(val)
      if(this.tableValue){
        this.loading=true
        var url = "v1/get-struktur-pokja-admin"
        let current = store.state.user.current
        let user = store.state.user
        if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
					current.username = _g.overrideWithPLT(user.current.role)
        }else{
					current.username = user.current.username
        }
        var base_url_api = _g.getBaseURLAPIERK(current.year)
        base_url_api = '/api/';
        const url_ = base_url_api + url
        const config = {
            headers:{
              "x-access-token": user.data.token,
              "Content-Type": "application/json"
            }
        }
        axios.post(url_, {nip: current.username, api: 1, page:this.tableValue.page, perpage: this.tableValue.rowPerPage, row_per_page: this.tableValue.rowPerPage, opd: this.selectSKPD, search: this.search, role:user.current.role},config).then(response => {
					let res = response.data
          var pokja_id = this.pokja_id
          var views_ = []
					if (res.success){
						this.items = response.data.data
            if(response.data.data != undefined){
              _.each(response.data.data, function(value){
                if(pokja_id == parseInt(value.pokja_id)){
                  views_ = value
                }
              })
            }
            this.views = views_
            this.totalKeseluruhan = response.data.total_keseluruhan
            console.log(this.items)
						store.commit('snackbar/setSnack',{message: res.message, color:'success'})
					} else {
						store.commit('snackbar/setSnack',{message: res.message, color:res.response})
					}
					this.loading=false
        }).catch(err => {
					var error = err.message
					store.commit('snackbar/setSnack',{message: error, color:'error'})
        })
      }
    },

    getSKPDUnitKerja(val){
      var url = "v1/get-data-skpd-unit-kerja"
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      base_url_api = '/api/';
      const url_ = base_url_api + url
      const config = {
         headers:{
            "x-access-token": user.data.token,
            "Content-Type": "application/json"
         }
      }
      var data = []
      axios.post(url_, {satuan_kerja_id: val},config).then(response => {
        _.each(response.data.data, function(value, key, obj){
          data.push({k:key, v: value})
        })
      }).finally(()=>{
        this.unit_kerja = data
      }).catch(err => {
        var error = err.response.data.message
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },

    clearForm(){
      // this.formTambah = {}
      // this.fromDateVal = null
      // this.selectedJenisPBJ = null
      this.formEdit = {}
      this.formEdit.file=[]
      this.editState = false
      // this.showItem = false
    },

    createItem(){
      this.clearForm()
      this.dialogTambah=true
      this.fromDateVal = null
      this.endDateVal = null
      // this.formTambah.file=[]
      // this.$refs.formTambah.resetValidation()
      setTimeout(()=>{
        document.getElementsByClassName('v-dialog--active')[0].scrollTop = 0
      },500)
    },

    edit(item){
      this.formEdit = _.clone(item)
      this.formEdit.ketua={}
      this.formEdit.ketua.peg_nip = null
      this.formEdit.ketua.peg_nama = null
      this.formEdit.atasan={}
      this.formEdit.atasan.peg_nip = null
      this.formEdit.atasan.peg_nama = null
      this.fromDateVal = null
      this.endDateVal = null
      this.fromDateVal_ = null
      this.endDateVal_ = null
      this.dialogTambah = true
      this.editState = true
      this.formEdit.satuan_kerja_id = item.satuan_kerja_id.toString()
      this.loadUkerAndPegawai(this.formEdit.satuan_kerja_id)
      this.formEdit.unit_kerja_id = item.unit_kerja_id != null ? item.unit_kerja_id.toString() : null
      this.fromDateVal = item.tanggal_mulai
      this.endDateVal = item.tanggal_selesai
      this.formEdit.ketua.peg_nip = item.nip_ketua
      this.formEdit.ketua.peg_nama = item.data_ketua.nama_tanpa_gelar
      this.formEdit.atasan.peg_nip = item.nip_atasan
      this.formEdit.atasan.peg_nama = item.data_atasan_ketua.nama_tanpa_gelar
    },

    hapus(item){
      this.formEdit = _.clone(item)
      this.dialogHapus = true
    },

    lihat(item){
      // this.formEdit = _.clone(item)
      // this.selectSKPD = item.satuan_kerja_id
      this.pokja_id = item.pokja_id
      this.satuan_kerja_id = item.satuan_kerja_id
      this.updateTable()
      this.getDataPegawaiSKPD(item.satuan_kerja_id)
      this.dialogLihat = true
    },

    tutup(){
      this.dialogTambah=false
      this.dialogLihat=false
      this.selectSKPD = ""
      let val = {itemsPerPage:10, page: 1}
      this.updateTable(val)
    },

    loadUkerAndPegawai(v){
      let val = v ? v : this.formEdit.satuan_kerja_id
      this.unit_kerja_l = true
      this.getSKPDUnitKerja(val)
      this.unit_kerja_l = false
      this.getDataPegawaiSKPD(val)
    },

    simpanItem(){
      let user = store.state.user
      var url = "v1/store-struktur-pokja-admin"
      let current = store.state.user.current
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_ = base_url_api + url
      this.formEdit.tanggal_mulai = this.fromDateVal
      this.formEdit.tanggal_selesai = this.endDateVal
      this.formEdit.nip_ketua = this.formEdit.ketua.peg_nip
      this.formEdit.nip_atasan = this.formEdit.atasan.peg_nip
      this.formEdit.is_super_admin = true
      this.formEdit.user = this.username
      const config = {
         headers:{
            "x-access-token": user.data.token,
            "Content-Type": "application/json"
         }
      }
      axios.post(url_, {...this.formEdit},config).then(response => {
        if (response.data.success){
          store.commit('snackbar/setSnack',{message: response.data.message, color:'success'})
          this.editState = false
          this.dialogTambah = false
          this.updateTable()
        } else {
          this.editState = false
          store.commit('snackbar/setSnack',{message: response.data.message, color:'error'})
        }
      }).catch(err => {
        var error = err.response.data.message
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    hapusItem(){
      var url = "v1/delete-struktur-pokja-admin"
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }
      const url_ = _g.getBaseURLAPIERK(current.year) + url
      const config = {
            headers:{
              "x-access-token": user.data.token,
              "Content-Type": "application/json"
            }
        }
      axios.post(url_, {user: this.username, nip: current.username, pokja_id: this.formEdit.pokja_id},config).then(response => {
        if (response.data.success){
          store.commit('snackbar/setSnack',{message: response.data.message, color:'success'})
          this.editState = false
          this.updateTable()
        } else {
          this.editState = false
          store.commit('snackbar/setSnack',{message: response.data.message, color:'error'})
        }
        this.dialogHapus = false
      }).catch(err => {
        var error = err.response.data.message
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    getDataPegawaiSKPD(satuan_kerja_id){
      var kode_skpd = local.getLocal("kolok")
      if(kode_skpd == null){
        kode_skpd = this.$store.getters["user/data"].data_asn["kolok"]
      }
      this.listPegawaiSKPD = []
      this.listPegawaiSKPDJabatan = []
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }

      var base_url_api = _g.getBaseURLAPIERK(current.year)
      base_url_api = '/api/';
      const url = 'v1/get-pegawai-skpd-req-post-sistem-kerja'
      const config = {
         headers:{
            "x-access-token": user.data.token,
            "Content-Type": "application/json"
         }
      }
      const url_get_data = base_url_api + url
      this.loadingListAnggota=true
      axios.post(url_get_data, {
        nip: current.username, satuan_kerja_id: satuan_kerja_id
      },config).then(response => {
        response.data.data.forEach(element => {
          element.foto = this.getImg(element.peg_nip, element.peg_foto)
        });
        let data = []
        let dataPegawai = []
        // var id_pokja = this.formEdit.pokja_id
        var id_pokja = this.pokja_id
        // var satuan_kerja_id = this.formEdit.satuan_kerja_id
        var satuan_kerja_id = this.satuan_kerja_id
        _.each(response.data.data, function(value){
          if(value.atasan_nip != null && value.atasan_nip.includes('PLT')){
            data.push({peg_nip: value.atasan_nip, peg_nama: value.atasan_nama_tanpa_gelar, nip_atasan:null, atasan_nama:null})
          }
          data.push({peg_nip: value.peg_nip, peg_nama: value.peg_nama, nip_atasan:value.atasan_nip, atasan_nama:value.atasan_nama_tanpa_gelar})
          dataPegawai.push({peg_nip: value.peg_nip, peg_nama: value.peg_nama, jabatan: value.jabatan, pokja_id:id_pokja, foto:value.foto, satuan_kerja_id: satuan_kerja_id})
        })
        // this.listPegawaiSKPD = response.data.data
        this.listPegawaiSKPD = data
        this.listPegawaiSKPDJabatan = dataPegawai
        this.loadingListAnggota=false
      }).catch(err => {
        this.loadingListAnggota=false
        var error = err.message ? err.message : err.response.data.message
        this.loadingEl = -1
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    getImg(nip, peg_foto){
      let avatarImgUrl = "https://simpeg.bandung.go.id/uploads/" + peg_foto
      this.avatarImages[nip] = avatarImgUrl
      return avatarImgUrl
    },

    getNipNama(item) {
      return `${item.peg_nip} - ${item.peg_nama}`;
      // return `${item.nip} - ${item.nama}`;
    },

    gFD(d){
      return _g.getFullDate(d)
    },

    gFDD(d){
      return _g.getFullDateDay(d)
    },

    check(d){
      let startDateTrim = d.tanggal_selesai.split("-")
      let startDate = new Date(parseInt(startDateTrim[0]), (parseInt(startDateTrim[1])-1), parseInt(startDateTrim[2]))
      let dateNow = new Date()
      let count = 0;
      const curDate = new Date(startDate.getTime());
      while (dateNow <= curDate) {
          const dayOfWeek = dateNow.getDay();
          if(dayOfWeek !== 0 && dayOfWeek !== 6) count++;
          dateNow.setDate(dateNow.getDate() + 1);
      }
      let endDueDate = count
      if(endDueDate <= 1){
        return 'error'
      }else if(endDueDate <= 3){
        return 'orange'
      }else{
        return 'green'
      }
    },

    tambahkanAnggota(v){
      let current = store.state.user.current
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_add_struktur_pokja = base_url_api + 'v1/add-struktur-pokja'
      axios.post(url_add_struktur_pokja, {
        user: this.username,
        nip: v.peg_nip,
        pokja_id: v.pokja_id,
        tanggal_mulai: null,
        tanggal_selesai: null,
        keterangan: null,
        is_super_admin: true
      }).then(response => {
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
          this.getDataPegawaiSKPD(v.satuan_kerja_id)
          this.updateTable()
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:res.response})
        }
      }).finally(()=>{
        this.dialogLihat = true
      }).catch(err => {
        var error = err.response.data.message
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    setAsLeader(item, idx){
      this.loadingEl = idx
      this.loadingBtn.upd = true
      SistemKerjaService.updStrukturPokja(item.nip, this.pokja_id).then(response => {
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
          this.getDataPegawaiSKPD(this.satuan_kerja_id)
          this.updateTable()
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:res.response})
        }
      }).finally(()=>{
        this.loadingEl = -1
        this.loadingBtn.upd = false
      })

      SistemKerjaService.setAsRoleKetua(item.nip, this.pokja_id).then(response => {
        let res = response.data
        if(res.status){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
          this.getDataPegawaiSKPD(this.satuan_kerja_id)
          this.updateTable()
        } else {
          store.commit('snackbar/setSnack',{message: 'Terjadi kesalahan saat menyimpan peran ketua pokja: '+res.message, color:res.response})
        }
      }).catch(err => {
					var error = err.message
					store.commit('snackbar/setSnack',{message: error, color:'error'})
      }).finally(()=>{
        this.loadingEl = -1
        this.loadingBtn.upd = false
      })
    },

    hapusAnggota(item, idx) {
      this.loadingEl = idx
      this.loadingBtn.del = true
      SistemKerjaService.delStrukturPokja(item.nip, this.pokja_id).then(response => {
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
          this.getDataPegawaiSKPD(this.satuan_kerja_id)
          this.updateTable()
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:res.response})
        }
      }).finally(()=>{
        this.loadingEl = -1
        this.loadingBtn.del = false
      })
    },

    lookUpAtasan(){
      if(this.formEdit.ketua != null){
        if(this.formEdit.ketua.atasan_nama != null){
          this.formEdit.atasan={}
          this.formEdit.atasan.peg_nip = this.formEdit.ketua.nip_atasan
          this.formEdit.atasan.peg_nama = this.formEdit.ketua.atasan_nama
          this.disabled_atasan = true
        }else{
          this.disabled_atasan = false
        }
      }else{
        this.disabled_atasan = false
      }
    },

    checkJumlahAnggota(item){
      var v = item.jumlah_anggota - item.data_anggota.length
      if(v <= 1){
        return 'error'
      }else if(v <= (item.jumlah_anggota/2)){
        return 'orange'
      }else{
        return 'green'
      }
    },
  }
}
</script>

<style lang="css" scoped>
</style>
